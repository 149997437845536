.header{
  position: absolute;
  left: 0;
  top: 2.75em;
  width: 100%;

  @media (max-width: $md) {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    transition: all .2s ease;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,.7);
    backdrop-filter: blur(12px);
    border-radius: 0;
    z-index: 1000;
    padding-top: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    &-active{
      transform: translateX(0);
    }
  }

  &-fixed{
    position: fixed;
    z-index: 1000;
    top: 0;
    background: #f0f0f0;
    padding: .75em 0;
    animation: showHeader .3s ease;
  }
  &__menu_icon{
    display: none;

    @media (max-width: $md) {
      display: block;
    }
  }
  &__content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md) {
      flex-direction: column;
    }
  }
  &__logo{
    width: 15.388em;
  }

  &__phone{
    font-size: $fz-36;
    text-decoration: none;
    color: #000;

    &_code{
      font-weight: 300;
    }
    &_number{
      font-weight: 600;
    }
  }

  &__leftside{
    width: 68.5%;
  }
  @media (max-width: $md) { 
    & .nav__list{
      flex-direction: column;
    }   
    & .nav__item{
      margin-bottom: 1em;
      &::before{
        display: none;
      }
    }
    & .nav__link{
      font-size: 20px;
    }
  }
}