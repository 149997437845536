.modal{
  position: fixed;
  z-index: 100;
  bottom: 40px;
  left: 40px;
  background: #fff;
  padding: 50px;
  box-shadow: 0 6px 20px rgba(0, 16, 61, .07);
  border-radius: 16px;
  transition: box-shadow .5s, transform .3s ease;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $xs) {
    width: calc(100% - 40px);
    bottom: 90px;
    left: 20px;
    padding: 30px 20px 20px;
  }

  &:hover{
    box-shadow: 0 6px 20px rgba(0, 16, 61, .2);
  }

  &-promocode{
    transform: translateX(calc(-100% - 40px));

    @media (max-width: $xs) {
      width: calc(100% - 40px);
      bottom: initial;
      top: 50%;
      transform: translate(calc(-100% - 40px), -50%);
      left: 20px;
      padding: 30px 20px 20px;

      &.modal-active{
        transform: translate(0, -50%);
      }
    }

    &.modal-active{
      transform: translateX(0);
    }
  }

  &__close{
    position: absolute;
    z-index: 30;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s ease;
    cursor: pointer;
    background: url(~/images/modal__close.svg) no-repeat 50%;
    background-size: contain;
    opacity: .7;
    transition: opacity .3s;

    &:hover{
      opacity: 1;
    }

    @media (max-width: $xs) {
      top: 12px;
      right: 12px;
      width: 20px;
      height: 16px;
    }
  }

  &__title{
    font-weight: 700;
    font-size: 1.222em;
    margin-bottom: 20px;
  }

  &__text{
    font-size: .9em;
    line-height: 1.2;
    margin-bottom: 1em;
  }

  &__promocode{
    padding-bottom: 10px;
    cursor: pointer;
    letter-spacing: 2px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    background: #f4f4f4;
    transition: .2s ease;

    &-copied{
      background: #8ce9a0;
    }

    &_link{
      position: relative;
      top: 0.15em;
      display: inline-block;
      width: 5em;
      height: 1em;
      background-image: url(~/images/logo-apteka-ru.svg);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: .9;
      transition: .2s ease;

      &:hover{
        opacity: 1;
      }
    }
  }
}