
.footer{

  &__promo{
    margin-top: 1.2em;
    text-align: center;
    font-size: 30px;
    line-height: 1.67;

    @media (max-width: $md) {
      font-size: 28px;
    }
    @media (max-width: $md) {
      font-size: 20px;
    }

    &-bold{
      display: block;
      font-weight: bold;
    }
    &-phone{
      text-decoration: none;
      color: #000;
      &:hover{
        color: $red;
        text-decoration: underline;
      }
    }
  }
  
  &__nav{
    width: 100%;
    padding: 2.1em 0;
    background: $red;

    @media (max-width: $md) {
      position: relative;
      background: transparent;
      width: 100%;
      left: inherit;
      transform: translateY(0);
      padding: 0;
      margin-bottom: 2.5em;

      & .nav__list{
        align-items: flex-start;
      }
    }

    & .nav__list{
      justify-content: space-around;
    }

    & .nav__item{
      
      @media (max-width: $md) {
        margin-bottom: 0.75em;
        padding: 0;
        &:first-child{
          padding-left: 0;
        }
        &:last-child{
          padding-right: 0;
        }
      }
      &::before{
        color: #fff;
        @media (max-width: $md) {
          color: $red;
        }
      }
    }
    & .nav__link{
      font-weight: normal;
      font-size: $fz-24;
      line-height: 1.25;
      color: #fff;
      &:hover{
        color: #000;
      }
      @media (max-width: $md) {
        padding: 0;
        color: $red;
        &:hover{
          color: #000;
        }
      }
      @media (max-width: $md) {
        font-size: 1.2em;
      }
    }
    
  }
  
  &__row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3.25em;
    
    @media (max-width: $sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__col{
    &-leftside{
      margin-right: 30px;
      width: 23%;

      @media (max-width: $sm) {
        margin-right: 0;
        width: 100%;
        margin-bottom: 3em;
      }
    }
    &-right{
      @media (max-width: $sm) {
        width: 100%;
      }
    }
  }

  &__text{

    &-small{
      margin-top: 1em;
      display: block;
      font-size: 80%;
    }
  }

  &__link{
    color: $red;
    text-decoration: underline;
    
    &:hover{
      color: #000;
      text-decoration: none;
    }
  }

  &__logo{
    width: 190px;
  }
}