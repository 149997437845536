@import '../css/reset.css';
@import './vars.scss';
@import './header.scss';
@import './nav.scss';
@import './modal.scss';
@import './footer.scss';

body,
html{
  overflow-x: hidden;
}
body{
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  background: #fff;
  font-weight: 400;

  @media (max-width: $lg) {
    font-size: 14px;
  }
}
sup{
  font-size: 0.6em;
}
.container{
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1280px;

  @media (max-width: $lg) {
    max-width: $md;
  }
  @media (max-width: $md) {
    max-width: $sm;
  }
  @media (max-width: $sm) {
    max-width: 540px;
  }
}
.menu_icon{
  position: absolute;
  right: calc(-2em - 30px);
  top: 3em;
  transform: translateY(-50%);
  width: 2.2em;
  height: 2.5em;
  cursor: pointer;
  transition: 0.5s ease-out all;

  &__lines{
    position: absolute;
    left:0;
    top: calc(50% - 0.125rem);
    width: 100%;
    height: 0.3em;
    display: block;
    background: $red;
    transition: 0.2s ease-out all;

    &:after,
    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 0.3em;
      display: block;
      background: $red;
      transition: 0.2s ease-out all;
    }

    &:before{
      transform: translateY(-0.75em);
    }
    &::after{
      transform: translateY(.75em);
    }
  }
  
  &-active {
    right: 2em;
    & .menu_icon__lines{
      height: 0;
      &:before{
        transform: rotate(45deg);
      }
      &:after{
        transform: rotate(-45deg)
      }
    }
  }
}

@keyframes showHeader {
  from {
    transform: translateY(-100%)
  }
  to {
    transform: translateY(0);
  }
}



.title{
  font-weight: bold;
  font-size: $fz-60;
  line-height: 1;
  text-align: center;

  @media (max-width: $lg) {
    font-size: 2.333em;
  }
  @media (max-width: $sm) {
    font-size: 2em;
  }
}

.button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-radius: 2em;
  transition: all .2s ease;
  border: 3px solid $red;
}

.section-1{
  background: url(~/images/section-1__bg.jpg) no-repeat center center;
  background-size: cover;
  padding: 10em 0 4.333em;
  @media (max-width: $sm) {
    padding: 0;
    background: #fff;

    & .container{
      position: relative;
      z-index: 5;
    }

    &__top-mobile{
      position: relative;
    }
    &__img-min{
      position: absolute;
      bottom: -6em;
      right: -1em;
      width: 54%;
    }
  }

  @media (min-width: $sm) {
    &__bg-min{
      display: none;
    }
    &__img-min{
      display: none;
    }
  }

  &__row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: $sm) {
      flex-direction: column;
    }
  }
  &__col{
    width: 53%;
    @media (max-width: $md) {
      width: 60%;
    }
    @media (max-width: $sm) {
      width: 100%;
    }
  }
  &__title{
    font-size: 72px;
    line-height: 0.9;
    font-weight: bold;
    @media (max-width: $lg) {
      font-size: 53px;
    }
    @media (max-width: $md) {
      font-size: 40px;
    }
    @media (max-width: $sm) {
      font-size: 2.66em;
      margin-top: 1.5em;
      width: 66%;
    }
  }
  &__subtitle{
    margin-top: .9em;
    font-size: 30px;
    line-height: 1.2;
    max-width: 18em;
    @media (max-width: $lg) {
      font-size: 24px;
    }
    @media (max-width: $md) {
      font-size: 20px;
    }
    @media (max-width: $sm) {
      font-size: 1.75em;
      margin-top: 1.2em;
    }
  }

  &__list{
    margin-top: 5.2em;

    @media (max-width: $sm) {
      margin-top: 2em;
    }
    &_item{
      position: relative;
      margin-bottom: .4em;
      line-height: 1.66;
      padding-left: 2.66em;
      &::before{
        content:'';
        position: absolute;
        left: 0;
        top: 0.5em;
        background: url(~/images/list__arrow.png) no-repeat center center;
        background-size: contain;
        width: 1.9em;
        height: 0.6em;
      }
    }
  }
}

.subsection-1{
  padding: 2em 0 1.33em;
  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $sm) {
      flex-direction: column-reverse;
    }
  }
  &__col{
    &-leftside{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 74%;
      @media (max-width: $sm) {
        flex-direction: column;
        width: 100%;
      }
    }
    &-rightside{
      position: relative;
      width: 26%;
      @media (max-width: $sm) {
        width: 100%;
      }
    }
  }
  &__item{
    display: flex;
    flex-direction: row;
    align-items: center;

    &_icon{
      margin-right: 9px;
    }
    @media (max-width: $sm) {
      margin-bottom: 2em;
    }
  }
  &__img{
    position: absolute;
    bottom: -3em;
    right: -5em;
    width: 132%;
    max-width: inherit;
    @media (max-width: $sm) {
      display: none;
    }
  }
}

.section-2{
  background: #f0f0f0;
  padding: 5em 0 5em;

  &__items{
    position: relative;
    margin-top: 3em;
    @media (max-width: $sm) {
      overflow: hidden;
    }
    & .swiper{
      &-wrapper{
        @media (min-width: $sm) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
        }
        @media (min-width: $md) {
          justify-content: space-around;
        }
      }
    }
  }
  &__item{
    max-width: 230px;
    text-align: center;

    @media (max-width: $lg) {
      max-width: 180px;
    }
    @media (max-width: $md) {
      max-width: inherit;
      width: 33%;
      margin-bottom: 2em;
    }
    @media (max-width: $sm) {
      width: 100%;
      margin-bottom: 1.5em;
    }
    &_text{
      font-weight: 600;
    }
    &_icon{
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 120px;
      margin-bottom: 1.5em;
    }
  }
}

.section-3{
  padding: 3em 0 8.5em;
  
  @media (max-width: $sm) {
    padding: 4em 0;
  }

  &__text{
    line-height: 1.67;
  }
  &__list{
    margin-top: 3em;
    &_item{
      position: relative;
      margin-bottom: 1.75em;
      line-height: 1.66;
      padding-left: 4.5em;
      &::before{
        content:'';
        position: absolute;
        left: 0;
        top: 0.5em;
        background: url(~/images/list__arrow.png) no-repeat center center;
        background-size: contain;
        width: 1.9em;
        height: 0.6em;
      }
    }
  }
  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3.66em;
    @media (max-width: $sm) {
      flex-direction: column-reverse;
    }
  }
  &__col{
    position: relative;
    z-index: 1;
    &-leftside{
      width: 47%;
      @media (max-width: $sm) {
        margin-top: 2em;
      }
    }
    &-rightside{
      position: relative;
      width: 53%;
    }
    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__img{
    position: absolute;
    right: -10%;
    top: 0;
    width: 120%;
    max-width: inherit;
    @media (max-width: $md) {
      position: relative;
      top: inherit;
      right: inherit;
      width: 100%;
    }
    &_title{
      position: absolute;
      z-index: 1;
      top: 0;
      left: 40%;
      text-align: center;
      font-weight: 600;
      line-height: 1.67;
      @media (max-width: $sm) {
        position: relative;
        top: inherit;
        left: inherit;
        margin-bottom: 1.5em;
      }
    }
    &_text{
      position: absolute;
      top: 75.5%;
      left: -3.9em;
      width: 280px;
      text-align: center;
      @media (max-width: $lg) {
        top: 70%;
        width: 236px;
      }
      @media (max-width: $md) {
        position: relative;
        top: inherit;
        left: inherit;
        width: 100%;
        text-align: left;
        margin-top: 2em;
      }
      @media (max-width: $sm) {
        display: none;
      }

      &-small-light{
        display: block;
        line-height: 1.25;
        font-size: 24px;
        font-weight: 300;

        @media (max-width: $lg) {
          font-size: 22px;
        }
        @media (max-width: $md) {
          display: inline-block;
          font-size: 1.33em;
        }
      }
      &-big-semi{
        font-size: 30px;
        font-weight: 600;
        line-height: 1;
        @media (max-width: $lg) {
          font-size: 24px;
        }
        @media (max-width: $lg) {
          font-size: 1.33em;
        }
      }
    }
  }
  &__text{
    line-height: 1.7;
  }
  &__links{
    margin-top: 5.33em;
    display: flex;
    flex-direction: row;

    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
    &_item{
      margin-right: 34px;
      &:last-child{
        margin-right: 0;
      }
      @media (max-width: $sm) {
        margin-right: 10px;
        font-size: 13px;
      }
    }
  }
  &__link{
    color: #a52239;
    text-decoration: underline;
    line-height: 1.67;
    &:hover{
      text-decoration: none;
    }
  }
}

.section-4{
  position: relative;
  background: #f0f0f0;
  padding: 4.66em 0 2.5em;

  &::before{
    content: '';
    position: absolute;
    right: 0;
    bottom: 10%;
    width: 52%;
    height: 70%;
    background: url(~/images/section-4__bg.png) no-repeat right center;
    background-size: contain;

    @media (max-width: $sm) {
      display: none;
    }
  }
  &__img{
    @media (max-width: $sm) {
      display: block;
      position: relative;
      right: -15px;
    }
    @media (min-width: $sm) {
      display: none;
    }
  }

  & .container{
    position: relative;
    z-index: 2;
  }

  &__title{
    text-align: center;
  }

  &__subtitle{
    margin: 1.5em auto 2.25em;
    text-align: center;
    font-size: 30px;
    line-height: 1.2;
    width: 85%;
    @media (max-width: $lg) {
      font-size: 20px;
    }
    @media (max-width: $sm) {
      width: 100%;
      font-size: 17px;
    }
  }
  
  &__row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: $sm) {
      flex-direction: column;
    }
  }
  &__col{
    width: 50%;
    &-leftside{
      padding-bottom: 6.67em;
      @media (max-width: $sm) {
        padding-bottom: 0;
        margin-bottom: 3em;
      }
    }
    &-rightside{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    @media (max-width: $sm) {
      width: 100%;
    }
  }
  &__text{
    @media (max-width: $sm) {
      text-align: center;
    }
    &-semi{
      font-weight: 600;
      font-size: 24px;
      line-height: 1.25;
      margin-bottom: 1.33em;

      @media (max-width: $lg) {
        font-size: 20px;
      }
      @media (max-width: $sm) {
        font-size: 17px;
      }
    }
  }

  &__list{
    margin-top: 2.33em;

    &_block{
      margin-top: 3em;
      padding-left: 2.7em;
    }

    &_item{
      position: relative;
      padding-left: 0.9em;
      margin-bottom: 9px;
      &:before{
        content: '+';
        position: absolute;
        left: 0;
        top: -1px;
      }
    }
  }

  &__where{
    padding: 4em 3em 4em 2.33em;
    background: #ebebeb;
    box-shadow: 0.5em 1em 1em rgba(0, 0, 0, .42);

    @media (max-width: $sm) {
      width: 100%;
    }

    &_title{
      font-weight: 600;
      line-height: 1.67;
      margin-bottom: 1.66em;
    }
    &_item{
      line-height: 1.67;
    }
  }

  &__text_after{
    margin-top: 1.15em;
    text-align: center;
    color: $red;
    @media (max-width: $md) {
      margin-top: 3em;
    }
    &-semi{
      font-weight: 600;
      font-size: 24px;
      line-height: 1.25;
      display: block;
      @media (max-width: $md) {
        font-size: 18px;
      }
      @media (max-width: $md) {
        font-size: 16px;
      }
    }
    &-light{
      font-weight: 300;
      font-size: 30px;
      line-height: 1;
      @media (max-width: $md) {
        font-size: 20px;
      }
      @media (max-width: $md) {
        font-size: 18px;
      }
    }
  }
}

.section-5{
  padding: 6.25em 0 3.5em;

  &__title{
    text-align: center;
  }
  &__subtitle{
    font-weight: bold;
    font-size: $fz-36;
    margin-top: 1.66em;
    text-align: center;
    @media (max-width: $sm) {
      line-height: 1.2;
    }
  }
  &__slider{
    position: relative;
    margin-top: 4.25em;  
  }
  
  &__list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5em;
    position: relative;
    @media (max-width: $md) {
      justify-content: center;
    }
    @media (max-width: $sm) {
      flex-direction: column;
      align-items: center;
      overflow: hidden;
    }
    & .swiper{
      &-wrapper{
        @media (min-width: $sm) {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
        }
        @media (min-width: $md) {
          justify-content: space-between;
        }
      }
    }

    &_item{
      max-width: 230px;
      text-align: center;

      @media (max-width: $lg) {
        max-width: 180px;
      }
  
      @media (max-width: $md) {
        max-width: inherit;
        width: 33%;
        margin-bottom: 2em;
      }
      @media (max-width: $sm) {
        width: 100%;
      }
      &_icon{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 120px;
        margin-bottom: 2em;
      }
      &_text{
        line-height: 1.67;
      }
    }
  }

  &__items{
    @media (max-width: $sm) {
      overflow: hidden;
    }
    
    & .swiper{
      &-wrapper{
        @media (min-width: $sm) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
    }
  }

  &__item{
    width: 27%;
    text-align: center;
    line-height: 30px;

    @media (max-width: $lg) {
      line-height: 26px;
      width: 30%;
    }
    @media (max-width: $md) {
      line-height: 20px;
      width: 32%;
    }

    &_img_block{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2em;
    }
    &_title{
      font-size: $fz-24;
      font-weight: bold;
      margin-bottom: 1.33em;
    }
    &_text{
      margin-bottom: 1.5em;
    }
    &_link{
      color: $red;
      display: none;
    }
  }
}

.subsection-5{
  background: $red;
  padding: 4.33em 0 2.33em;
  color: #fff;

  &__title{
    font-weight: bold;
    font-size: 44.79px;
    line-height: 1;
    @media (max-width: $lg) {
      font-size: 30px;
    }
    @media (max-width: $sm) {
      font-size: 26px;
    }
  }
  &__subtitle{
    font-weight: 300;
    font-size: 44.79px;
    line-height: 1;
    @media (max-width: $lg) {
      font-size: 32px;
    }
    @media (max-width: $sm) {
      font-size: 26px;
    }
  }
  &__link{
    display: inline-block;
    color: #fff;
    text-decoration: underline;
    font-size: 17px;
    margin-top: 2em;
    display: none;
  }
  &__row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  @media (min-width: $sm) {
    &__col{
      &-rightside{
        margin-top: -1em;
      }
    }
  }
}

.section-6{

  & .container{
    position: relative;
    z-index: 10;
    padding-top: 222px;
    padding-bottom: 222px;
    
    @media (max-width: $md) {
      padding: 4em 15px;
    }

    &:before{
      content: '';
      position: absolute;
      z-index: 1;
      left: 24%;
      bottom: 0;
      background: url(~/images/section-6__img.png) no-repeat bottom;
      background-size: contain;
      width: 74%;
      height: 100%;
      @media (max-width: $sm) {
        height: 50%;
        width: 100%;
      }
    }
  }
  &__title{
    margin-bottom: 1.22em;
  }

  &__text{
    &-light{
      font-weight: 300;
    }
    &-semi{
      font-weight: 600;
    }
  }

  &__row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: $sm) {
      flex-direction: column;
    }
  }
  
  &__item{
    
    &-left{
      position: relative;
      z-index: 1;
      font-size: 30px;
      line-height: 1.2;
      margin-bottom: 1.3em;
      max-width: 420px;

      @media (max-width: $lg) {
        font-size: 26px;
        max-width: 380px;
      }
      @media (max-width: $sm) {
        font-size: 22px;
      }
    }

    &-circle{
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 10em;
      height: 9.5em;
      background: $red;
      border-radius: 100%;
      margin-right: 1.75em;
      margin-top: 2em;
      text-align: center;
      font-size: 1.1em;
      font-weight: 600;
      line-height: 1;
      color: #fff;
    }
  }
}

.section-7{
  background: #f2f2f2;
  padding: 7em 0 3em;
  @media (max-width: $md) {
    padding: 4em 0;
  }

  &__title{
    text-align: center;
  }

  &__uteka{
    margin-top: 3em;
  }

  &__banner{
    margin-top: 3em;
    display: block;
    width: 100%;

    &_img{
      width: 100%;
    }
  }

  &__items{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 6em;
    @media (max-width: $sm) {
      margin-top: 3em;
      flex-direction: column;
    }
  }

  &__item{
    width: 25%;
    margin-bottom: 3.5em;
    text-align: center;
    text-decoration: none;
    

    @media (max-width: $md) {
      width: 48%;
    }
    @media (max-width: $sm) {
      width: 100%;
      margin-bottom: 1.5em;
    }

    &_img_block{
      background: #fff;
      padding: 2em;
      box-shadow: .1em .1em .66em rgba(0, 0, 0, .5);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      height: 160px;
      @media (max-width: $sm) {
        width: 100%;
        height: 120px;
      }
    }

    &_price{
      margin-top: 1em;
      font-weight: 600;
      font-size: 1.2em;
      line-height: 1.5;
      color: $red;
    }
  }
}

.section-8{
  background: #fff;
  padding: 3.66em 0 1em;

  &__logo{
    margin-bottom: 4.5em;
    &_img{
      max-width: 468px;
      width: 100%;
    }
  }
  &__title{
    font-weight: bold;
    font-size: $fz-36;
    line-height: 0.8;
    margin-bottom: 1em;

    
    @media (max-width: $md) {
     font-size: 1.5em;
    }
  }
  &__list{
    margin-left: 1em;
    &_item{
      position: relative;
      margin-bottom: 1.67em;
      line-height: 1.67;
      list-style: decimal;
    }
  }

  &__row{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 7em;
    @media (max-width: $sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__col{
    &-leftside{
      width: 35%;
      
      @media (max-width: $sm) {
        width: 39%;
      }
      @media (max-width: $sm) {
        width: 100%;
        margin-bottom: 3em;
      }
    }
    &-rightside{
      width: 55%;
      @media (max-width: $sm) {
        width: 60%;
      }
      @media (max-width: $sm) {
        width: 100%;
        margin-bottom: 3em;
      }
    }
  }

  &__item{

    &_title{
      font-weight: bold;
      font-size: 2em;
      line-height: 1;
      margin-bottom: 0.5em;
    }
    &_text{
      font-weight: 600;
      font-size: 1.1em;
      line-height: 1;
    }
  }

  &__button{
    width: 278px;
    height: 80px;
    text-align: center;
    text-decoration: none;
    margin: 2em auto 0;

    @media (max-width: $sm) {
      width: 100%;
      height: 60px;
      margin-top: 1em;
    }

    &-white{
      color: $red;
      
      &:hover{
        color: #fff;
        background: $red;
      }
    }
    &-pink{
      color: #fff;
      background: $red;
      box-shadow: .25em 0.75em 1.33em rgba(0, 0, 0, .4);

      &:hover{
        background: transparent;
        color: $red;
      }
    }
  }
}

.swiper{
  &__pagination{
    margin-top: 20px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: $lg) {
      margin-top: 15px;
    }

    & .swiper-pagination-bullet{
      width: 16px;
      height: 16px;
      margin-left: 10px;
      margin-right: 10px;
      opacity: 1;
      background: #ccc;
      &-active{
        background: $red;
      }

      @media (max-width: $lg) {
        width: 10px;
        height: 10px;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
  
  &__navigation{

    &-next,
    &-prev{
      position: absolute;
      z-index: 100;
      top: 50%;
      width: 2.5em;
      height: 5em;
      transform: translateY(-50%);
      border-radius: 100%;
      
      @media (min-width: $sm) {
        display: none;
      }
    }

    &-prev{
      left: 0;
      background: url(~/images/chevron-left.svg) no-repeat 45% center;
      background-size: 40%;
    }
    &-next{
      right: 0;
      background: url(~/images/chevron-right.svg) no-repeat 55% center;
      background-size: 40%;
    }
  }
}

.danger{
  margin-top: 4.5em;
  background: #f0f0f0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 100;
  padding: 2.5em 0 2em;

  &__img{
    width: 100%;
  }

  @media (max-width: $md) {
    padding: 25px 0;
  }
}

.sale-fixed{
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  width: 9em;
  height: 8.75em;
  background: #c6122a;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 100%;

  text-decoration: none;
  font-size: 1.11em;
  font-weight: bold;
  color: #fff;
  transition: .3s all ease;

  &:hover{
    background: #c6122a*1.1;
  }
}
