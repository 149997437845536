$lg: 1380px;
$md: 991px;
$sm: 768px;
$xs: 540px;

$fz-60: 3.333em;
$fz-48: 2.666em;
$fz-36: 2em;
$fz-24: 1.333em;


//$red: #a9192d;
$red: #c6122a;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;900&display=swap');