
.nav{
  &__list{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: $sm) {
      flex-direction: column;
    }
  }
  &__link{
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    color: #000;
    line-height: 1;
    @media (max-width: $sm) {
      display: block;
      width: 100%;
      padding: 1em 0;
    }

    &:hover{
      color: $red;
    }

    &-buy{
      color: $red;
      border: 3px solid $red;
      width: 119px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 3px;
      @media (max-width: $sm) {
        display: flex;
      }
    }
  }
}